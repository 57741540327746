import { AntDesign } from "@expo/vector-icons";
import { View, Text, TextInput, StyleSheet, TouchableOpacity } from "react-native";
import { colours } from "../../../config";

export const QrModal = ({ equipment, onCancel, qr, setQr, onSave }) => {
    return (
        <View>
            <View style={styles.header}>
                <Text style={styles.headerText}>{equipment.qr ? "Edit" : "Add"} QR</Text>
            </View>

            <TextInput
                multiline={false}
                style={styles.form__input}
                onChangeText={(text) => setQr(text)}
                value={qr}
                placeholder="Enter QR Code"
            />

            <View style={styles.modalButtons}>
                <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
                    <Text style={styles.cancelButtonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.saveButton} onPress={onSave}>
                    <Text style={styles.saveButtonText}>{equipment.qr ? "Edit" : "Add"} QR</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        borderBottomColor: "#CECECE",
        borderBottomWidth: 1,
        paddingBottom: 5,
        alignItems: "center",
    },
    headerText: {
        fontWeight: "bold",
        fontSize: 24,
        flex: 1,
    },
    form__input: {
        fontFamily: "OpenSans-Regular",
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#CECECE",
        marginTop: 10,
        fontSize: 20
    },
    modalButtons: {
        flexDirection: "row",
        justifyContent: 'flex-end',
        marginTop: 10,
    },
    saveButton: {
        backgroundColor: colours.primary,
        padding: 10,
        borderRadius: 5,
        margin: 5
    },
    saveButtonText: {
        color: "#fff",
        fontWeight: "bold",
    },
    cancelButton: {
        backgroundColor: "#f44336",
        padding: 10,
        borderRadius: 5,
        margin: 5
    },
    cancelButtonText: {
        color: "#fff",
        fontWeight: "bold",
    },
});
