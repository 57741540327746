import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, TextInput, Platform } from "react-native";
import { MaterialCommunityIcons, AntDesign, EvilIcons, MaterialIcons, FontAwesome, Feather } from '@expo/vector-icons';
import { Controller, useForm } from "react-hook-form";
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";
import { Picker } from "@react-native-picker/picker";
import { colours } from "../../config";
import Checkbox from "expo-checkbox";

export function EquipmentExtra({ title, extras, onAddExtra, onRemoveExtra }) {
  const [extrasList, setExtrasList] = useState(extras);

  useEffect(() => {
    setExtrasList(extras);
  }, [extras])

  const updateSerial = (extra, text) => {
    extra.serialNum = text;
    setExtrasList([...extrasList]);
  }

  const updateMake = (extra, text) => {
    extra.make = text;
    setExtrasList([...extrasList]);
  }

  return (
    <View style={styles.equipment__extra__block}>
      <View style={styles.equipment__extra__title__block}>
        <Text style={styles.equipment__extra__title}>{title}</Text>
        <TouchableOpacity onPress={() => onAddExtra()}>
          <Text style={styles.equipment__extra__title__add__button__text}>Add</Text>
        </TouchableOpacity>
      </View>

      <View>
        {extrasList.map((value, index) => {
          return (
            <View key={index} style={{ flexDirection: 'row', padding: 5 }}>
              <View style={{ marginRight: 5 }}>
                <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                <TextInput
                  style={GlobalStyle.form__column__input}
                  onChangeText={(text) => updateSerial(value, text)}
                  value={value.serialNum}>
                </TextInput>
              </View>

              <View style={{ marginRight: 5 }}>
                <Text style={GlobalStyle.form__column__text}>Make (*)</Text>
                <TextInput
                  style={GlobalStyle.form__column__input}
                  onChangeText={(text) => updateMake(value, text)}
                  value={value.make}>
                </TextInput>
              </View>

              <TouchableOpacity onPress={() => onRemoveExtra(value)} style={{ flex: 1, backgroundColor: '#ebebeb', padding: 10, alignItems: 'center', alignSelf: 'flex-end' }}>
                <Text style={{ color: '#000' }}>Remove</Text>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export const EquipmentManageControl = ({ equipment, companies, locations, masterCategories, categories, manufacturers, onCancel, onSubmit, hideMenu = false, disablePickers = false }) => {
  const [selectedMasterCategoryName, setSelectedMasterCategoryName] = React.useState<string>("");
  const [selectedCategoryName, setSelectedCategoryName] = React.useState<string>("");

  const [couplingsList, setCouplingsList] = React.useState<any>([]);
  const [wireRopesList, setWireRopesList] = React.useState<any>([]);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      description: "",
      serialNumber: "",
      assetNumber: "",
      dateOfManufacture: "",
      proofLoad: "",
      swl: "",
      companyId: "",
      locationId: "",
      categoryId: "",
      manufacturerId: "",

      numberOfHoists: "0",
      hoistType: "",
      abusNo: "",
      yearOfCommissioning: "",
      masterCategoryId: "",

      doc: false,
      transposedStandards: "",
      nationalStandards: "",
      dateOfFirstUse: "",

      couplings: "N/A",
      wireRopes: "N/A",

      equipmentExtras: [],

      qr: "",

      isDeleted: false,
    }
  });

  const companyId = watch("companyId");
  const masterCategoryId = watch("masterCategoryId");
  const categoryId = watch("categoryId");
  const equipmentExtras = watch("equipmentExtras");

  useEffect(() => {
    if (masterCategories == null || masterCategoryId == null || masterCategoryId == "") {
      setSelectedMasterCategoryName("");
      return;
    }
    var masterCategory = masterCategories.find(c => c.id == masterCategoryId);
    setSelectedMasterCategoryName(masterCategory.name);
  }, [masterCategoryId])

  useEffect(() => {
    if (categories == null || categoryId == null || categoryId == "") {
      setSelectedCategoryName("");
      return;
    }

    var category = categories.find(c => c.id == categoryId);
    setSelectedCategoryName(category.name);

    updateCouplingsList(category.name);
    updateWireRopesList(category.name);
  }, [categoryId]);

  useEffect(() => {
    if (masterCategories != null && masterCategories.length > 0) {
      setValue("masterCategoryId", masterCategories[0].id);
    }
  }, [masterCategories])

  const updateCouplingsList = (categoryName) => {
    var list = [];
    if (categoryName == "ASC") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("N/A"); }
    else if (categoryName == "ECH") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("Chain"); list.push("N/A"); }
    else if (categoryName == "Forklift") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("Chain"); list.push("N/A"); }
    else if (categoryName == "Mobile Crane") { list.push("Luffing"); list.push("Hoist"); list.push("N/A"); }
    else if (categoryName == "Quay Crane") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("N/A"); }
    else if (categoryName == "Straddle") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("N/A"); }
    else if (categoryName == "RMG") { list.push("Boom"); list.push("Hoist"); list.push("Trolley"); list.push("N/A"); }
    else list.push("N/A");

    setCouplingsList(list);
  }

  const updateWireRopesList = (categoryName) => {
    var list = [];
    if (categoryName == "ASC") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("N/A"); }
    else if (categoryName == "ECH") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("Fork Heel"); list.push("N/A"); }
    else if (categoryName == "Forklift") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("Fork Heel"); list.push("N/A"); }
    else if (categoryName == "Mobile Crane") { list.push("Hoist Ropes"); list.push("Luffing Ropes"); list.push("N/A"); }
    else if (categoryName == "Quay Crane") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("N/A"); }
    else if (categoryName == "Straddle") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("N/A"); }
    else if (categoryName == "RMG") { list.push("Hoist Ropes"); list.push("Boom Ropes"); list.push("Trolley Ropes"); list.push("TRS Ropes"); list.push("N/A"); }
    else list.push("N/A");

    setWireRopesList(list);
  }

  useEffect(() => {
    if (equipment != null) {
      var data = equipment;

      setValue("id", data.id);
      setValue("description", data.description);
      setValue("serialNumber", data.serialNumber);
      setValue("assetNumber", data.assetNumber);
      setValue("dateOfManufacture", data.dateOfManufacture);
      setValue("proofLoad", data.proofLoad);
      setValue("swl", data.swl);
      setValue("companyId", data.companyId);
      setValue("locationId", data.locationId);
      setValue("masterCategoryId", data.masterCategoryId);
      setValue("categoryId", data.categoryId);
      setValue("manufacturerId", data.manufacturerId);
      setValue("doc", data.doc);
      setValue("transposedStandards", data.transposedStandards);
      setValue("nationalStandards", data.nationalStandards);
      setValue("dateOfFirstUse", data.dateOfFirstUse);
      setValue("equipmentExtras", data.equipmentExtras ?? []);

      setValue("numberOfHoists", data.numberOfHoists);
      setValue("hoistType", data.hoistType);
      setValue("abusNo", data.abusNo);
      setValue("yearOfCommissioning", data.yearOfCommissioning);

      setValue("wireRopes", data.wireRopes);
      setValue("couplings", data.couplings);
      setValue("isDeleted", data.isDeleted);
      setValue("qr", data.qr);
    }
  }, [equipment]);

  const addEquipmentExtra = (type) => {
    var equipExtras = getValues("equipmentExtras");
    equipExtras.push({
      serialNum: "",
      make: "",
      equipmentExtraType: type
    });

    setValue("equipmentExtras", equipExtras)
  }

  const removeEquipmentExtra = (extra) => {
    var equipExtras = getValues("equipmentExtras");
    equipExtras.splice(equipExtras.indexOf(extra), 1);

    setValue("equipmentExtras", equipExtras)
  }



  const submit = async (data) => {
    onSubmit(data, data.id == null);
  };

  return (
    <View style={styles.container}>
      {!hideMenu && <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => onCancel()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE EQUIPMENT" : "UPDATE EQUIPMENT"}</Text>
      </View>
      }

      <View style={GlobalStyle.form}>
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}
                    enabled={!disablePickers}>

                    <Picker.Item label="Please Select" value="" />

                    {companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="locationId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={!disablePickers}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="manufacturerId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Manufacturer (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {manufacturers != null && manufacturers.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.manufacturerId && <Text style={GlobalStyle.form__input__validation}>Manufacturer is required.</Text>}
          </View>
        </View>


        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="serialNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.serialNumber && <Text style={GlobalStyle.form__input__validation}>Serial Number is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Description (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.description && <Text style={GlobalStyle.form__input__validation}>Description is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="assetNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Asset Number</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="dateOfManufacture"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Date Of Manufacture</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="proofLoad"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Proof Load</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="swl"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Safe Working Load (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.swl && <Text style={GlobalStyle.form__input__validation}>SWL is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}></View>

        <View style={GlobalStyle.form__row}>
          {/* <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="masterCategoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Master Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { masterCategories != null && masterCategories.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.masterCategoryId && <Text style={GlobalStyle.form__input__validation}>Master Category is required.</Text>}
          </View> */}

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="categoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {categories != null && categories != "" && categories.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.categoryId && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="couplings"
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Couplings (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />
                    {couplingsList.map((coupling) => {
                      return (
                        <Picker.Item key={coupling} label={coupling} value={coupling} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {/* {errors.couplings && <Text style={GlobalStyle.form__input__validation}>Couplings is required.</Text>} */}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="wireRopes"
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Wire Ropes (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {wireRopesList.map((wireRope) => {
                      return (
                        <Picker.Item key={wireRope} label={wireRope} value={wireRope} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {/* {errors.wireRopes && <Text style={GlobalStyle.form__input__validation}>Wire Ropes is required.</Text>} */}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="qr"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>QR</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        {Platform.OS == "web" &&
          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="isDeleted"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Expired</Text>
                    <Checkbox style={{ marginTop: 10 }} value={value} onValueChange={onChange} color={colours.primary} />
                  </View>
                )}
              />
            </View>
          </View>
        }

        {selectedCategoryName == "Davit Crane" &&
          <View style={GlobalStyle.form__row}>

            <View style={styles.equipment__extra__list}>
              <View style={{ flex: 1 }}>
                <EquipmentExtra
                  title={'Hoist(s)'}
                  extras={equipmentExtras != null && equipmentExtras.filter(e => e.equipmentExtraType == 0)}
                  onAddExtra={() => addEquipmentExtra(0)}
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>

              <View style={{ flex: 1 }}>
                <EquipmentExtra
                  title={'Cross Travel(s)'}
                  extras={equipmentExtras != null && equipmentExtras.filter(e => e.equipmentExtraType == 1)}
                  onAddExtra={() => addEquipmentExtra(1)}
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>

              <View style={{ flex: 1 }}>
                <EquipmentExtra
                  title={'Long Travel(s)'}
                  extras={equipmentExtras != null && equipmentExtras.filter(e => e.equipmentExtraType == 2)}
                  onAddExtra={() => addEquipmentExtra(2)}
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>
            </View>
          </View>
        }

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {

  },

  equipment__extra__list: {
    flexDirection: 'row',
    width: '100%',
  },
  equipment__extra__block: {
    flex: 1,
    margin: 5,
  },
  equipment__extra__title__block: {
    backgroundColor: colours.primary,
    padding: 10,
    flexDirection: 'row'
  },
  equipment__extra__title: {
    color: '#FFF',
    fontWeight: 'bold',
    flex: 1
  },

  equipment__extra__title__add__button__text: {
    color: '#FFF'
  }


});