import { store } from "..";
import { companyApi } from "./api/company-api";
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment";
import { CompanyDatabase } from "./database/company-database";
import { LocationDatabase } from "./database/location-database";
import { locationApi } from "./api/location-api";
import { categoryApi } from "./api/category-api";
import { CategoryDatabase } from "./database/category-database";
import { ManufacturerDatabase } from "./database/manufacturer-database";
import { manufacturerApi } from "./api/manufacturer-api";
import { EquipmentDatabase } from "./database/equipment-database";
import { equipmentApi } from "./api/equipment-api";
import { JobDatabase } from "./database/job-database";
import { ColourDatabase } from "./database/colour-database";
import { PurposeDatabase } from "./database/purpose-database";
import { jobApi } from "./api/job-api";
import { purposeApi } from "./api/purpose-api";
import { colourApi } from "./api/colour-api";
import { RAMSDatabase } from "./database/rams-database";
import { ramsApi } from "./api/rams-api";
import { serviceApi } from "./api/service-api";
import { ServiceDatabase } from "./database/service-database";
import { BreakdownDatabase } from "./database/breakdown-database";
import { breakdownApi } from "./api/breakdown-api";
import { InspectionDatabase } from "./database/inspection-database";
import { inspectionApi } from "./api/inspection-api";
import { EngineerReportDatabase } from "./database/engineer-report-database";
import { engineerReportApi } from "./api/engineer-report";
import { ProofLoadDatabase } from "./database/proof-load-database";
import { proofLoadApi } from "./api/proof-load-api";
import { DefectsDatabase } from "./database/defects-database";
import { defectsApi } from "./api/defects-api";
import { UserDatabase } from "./database/user-database";

export class SyncService {

  fullSync: boolean = false;



  private getUpdateTime = async (key) => {
    if (this.fullSync) {
      return "2000-01-01";
    }
    var lastUpdateTime = '2000-01-01';
    var lastUpdateTimeJson = await AsyncStorage.getItem(key);
    if (lastUpdateTimeJson != null && lastUpdateTimeJson != "") {
      lastUpdateTime = moment(lastUpdateTimeJson, "DD/MM/YYYY HH:mm:ss").add(-90, 'minutes').format("YYYY-MM-DD HH:mm:ss");
    }

    return lastUpdateTime;
  }

  private setUpdateTime = async (key, time) => {
    await AsyncStorage.setItem(key, time);
  }

  private syncCompanies = async () => {
    var companyDatabase = new CompanyDatabase();
    const key = "companies-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(companyApi.endpoints.syncCompanies.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await companyDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing companies: " + data.length);
  }

  private syncLocations = async () => {
    var locationDatabase = new LocationDatabase();
    const key = "locations-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(locationApi.endpoints.syncLocations.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await locationDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing locations: " + data.length);
  }


  private syncCategories = async () => {
    var categoryDatabase = new CategoryDatabase();
    const key = "categories-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(categoryApi.endpoints.syncCategories.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await categoryDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing categories: " + data.length);
  }

  private syncMasterCategories = async () => {
    var categoryDatabase = new CategoryDatabase();
    const key = "master-categories-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(categoryApi.endpoints.syncMasterCategories.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await categoryDatabase.insertOrUpdateMasterCategoriesList(data);
    }

    console.log("Finished syncing master categories: " + data.length);
  }

  private syncManufacturers = async () => {
    var manufacturerDatabase = new ManufacturerDatabase();
    const key = "manufacturers-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(manufacturerApi.endpoints.syncManufacturers.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await manufacturerDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing manufacturers: " + data.length);
  }

  private syncDefects = async () => {
    var defectsDatabase = new DefectsDatabase();
    const key = "defects-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(defectsApi.endpoints.syncDefects.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await defectsDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing defects: " + data.length);
  }

  private syncEquipment = async () => {
    var equipmentDatabase = new EquipmentDatabase();
    const key = "equipment-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(equipmentApi.endpoints.syncEquipment.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await equipmentDatabase.insertOrUpdateList(data, false);
    }

    console.log("Finished syncing equipment: " + data.length);
  }

  private syncJobs = async () => {
    var jobDatabase = new JobDatabase();
    const key = "jobs-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(jobApi.endpoints.syncJobs.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await jobDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing jobs: " + data.length);
  }

  private syncPurposes = async () => {
    var database = new PurposeDatabase();
    const key = "purposes-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(purposeApi.endpoints.syncPurposes.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await database.insertOrUpdateList(data);
    }

    console.log("Finished syncing purposes: " + data.length);
  }

  private syncColours = async () => {
    var database = new ColourDatabase();
    const key = "colours-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(colourApi.endpoints.syncColours.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await database.insertOrUpdateList(data);
    }

    console.log("Finished syncing colours: " + data.length);
  }

  private syncRAMSQuestions = async () => {
    var database = new RAMSDatabase();
    const key = "rams-questions-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(ramsApi.endpoints.syncRAMSQuestions.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await database.insertOrUpdateQuestionList(data);
    }

    console.log("Finished syncing rams questions: " + data.length);
  }


  // private syncServiceQuestionCategories = async () => {
  //   var database = new ServiceDatabase();
  //   const key = "service-question-categories-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestionCategories.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdateQuestionCategoryList(data);
  //   }

  //   console.log("Finished syncing service question categories: " + data.length);
  // }

  // private syncServiceQuestions = async () => {
  //   var database = new ServiceDatabase();
  //   const key = "service-questions-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestions.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdateQuestionList(data);
  //   }

  //   console.log("Finished syncing service questions: " + data.length);
  // }

  // private syncBreakdownCommonFaults = async () => {
  //   var database = new BreakdownDatabase();
  //   const key = "breakdown-common-faults-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(breakdownApi.endpoints.syncBreakdownCommonFaults.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdateCommonFaultsList(data);
  //   }

  //   console.log("Finished syncing breakdown common faults: " + data.length);
  // }

  // private syncBreakdownParts = async () => {
  //   var database = new BreakdownDatabase();
  //   const key = "breakdown-parts-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(breakdownApi.endpoints.syncParts.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdatePartsList(data);
  //   }

  //   console.log("Finished syncing breakdown parts: " + data.length);
  // }

  // private syncLoadTypes = async () => {
  //   var database = new EquipmentDatabase();
  //   const key = "load-types-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(equipmentApi.endpoints.syncLoadTypes.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdateLoadTypesList(data);
  //   }

  //   console.log("Finished syncing load types: " + data.length);
  // }

  // private syncLoadUnits = async () => {
  //   var database = new EquipmentDatabase();
  //   const key = "load-units-update-time";

  //   var lastSyncedTime = await this.getUpdateTime(key);
  //   var result = await store.dispatch(equipmentApi.endpoints.syncLoadUnits.initiate(lastSyncedTime)) as any;
  //   var data = result.data;
  //   await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

  //   if (data.length > 0) {
  //     await database.insertOrUpdateLoadUnitsList(data);
  //   }

  //   console.log("Finished syncing load units: " + data.length);
  // }

  private syncInspectionQuestions = async () => {
    var database = new InspectionDatabase();
    const key = "inspection-questions-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(inspectionApi.endpoints.syncInspectionQuestions.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("DD/MM/YYYY HH:mm:ss"));

    if (data.length > 0) {
      await database.insertOrUpdateQuestionsList(data);
    }

    console.log("Finished syncing inspection questions: " + data.length);
  }

  private sendEquipment = async () => {
    var database = new EquipmentDatabase();
    var equipments = await database.getForSync();
    for (let equipment of equipments) {
      var result = await store.dispatch(equipmentApi.endpoints.updateEquipment.initiate(equipment)) as any;
      if (!result.error) {
        await database.setToBeSynced(equipment.id, false);
      }
    }
  }

  private sendInspections = async () => {
    var database = new InspectionDatabase();

    var inspections = await database.getForSync();
    console.log("INSPECTIONS COUNT:" + inspections.length);
    for (let inspection of inspections) {
      var result = await store.dispatch(inspectionApi.endpoints.sendInspection.initiate(inspection)) as any;
      if (result.error) {
        console.log(result.error.data);
      }
      if (!result.error) {
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendJobs = async () => {
    var database = new JobDatabase();
    var jobs = await database.getJobsForSync();
    for (let job of jobs) {
      var result = await store.dispatch(jobApi.endpoints.sendJob.initiate(job)) as any;
      console.log(JSON.stringify(job));
      if (result.error) {
        console.log("'--'")
        console.log(result.error.data);
      }
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateJobToBeSynced(job.id, false);
      }
    }
  }

  private sendJobAssets = async () => {
    var database = new JobDatabase();

    var jobAssets = await database.getJobAssetsForSync();
    for (let jobAsset of jobAssets) {
      var result = await store.dispatch(jobApi.endpoints.sendJobAssets.initiate(jobAsset)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.updateJobAssetToBeSynced(jobAsset.id, false);
      }
    }
  }

  private sendRAMS = async () => {
    var database = new RAMSDatabase();

    var ramsList = await database.getForSync();

    for (let rams of ramsList) {
      var result = await store.dispatch(ramsApi.endpoints.sendRAMS.initiate(rams)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateRAMSToBeSynced(rams.id, false);
      }
    }
  }

  private sendEngineerReports = async () => {
    var database = new EngineerReportDatabase();

    var engineerReports = await database.getForSync();
    for (let engineerReport of engineerReports) {
      var result = await store.dispatch(engineerReportApi.endpoints.sendEngineerReport.initiate(engineerReport)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateEngineerReportToBeSynced(engineerReport.id, false);
      }
    }
  }

  private sendBreakdowns = async () => {
    var database = new BreakdownDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      var result = await store.dispatch(breakdownApi.endpoints.sendBreakdownInspection.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendServices = async () => {
    var database = new ServiceDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      //console.log(inspection);
      var result = await store.dispatch(serviceApi.endpoints.sendServiceInspection.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendProofLoads = async () => {
    var database = new ProofLoadDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      var result = await store.dispatch(proofLoadApi.endpoints.sendProofLoads.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendDefects = async () => {
    var database = new DefectsDatabase();

    var defects = await database.getForSync();
    for (let defect of defects) {
      var result = await store.dispatch(defectsApi.endpoints.createDefect.initiate(defect)) as any;
      if (!result.error) {
        defect.toBeSynced = false;
      }
    }

    await database.insertOrUpdateList(defects);
  }

  public syncAll = async () => {
    await this.sendAll();
    console.log("SENT ALL");
    await this.getAll();
  }

  public sendAll = (): Promise<void> => {
    return new Promise(async (resolve) => {
      await this.sendEquipment();

      await this.sendJobs();

      await this.sendRAMS();

      await this.sendJobAssets();

      await this.sendEngineerReports();

      await this.sendInspections();

      await this.sendBreakdowns();

      await this.sendServices();

      await this.sendProofLoads();

      await this.sendDefects();

      resolve();
    });
  }

  public initialiseAllDatabases = (): Promise<void> => {
    return new Promise(async (resolve) => {
      await new BreakdownDatabase().initialise();
      await new CategoryDatabase().initialise();
      await new ColourDatabase().initialise();
      await new CompanyDatabase().initialise();
      await new DefectsDatabase().initialise();
      await new EngineerReportDatabase().initialise();
      await new EquipmentDatabase().initialise();
      await new InspectionDatabase().initialise();
      await new JobDatabase().initialise();
      await new LocationDatabase().initialise();
      await new ManufacturerDatabase().initialise();
      await new ProofLoadDatabase().initialise();
      await new PurposeDatabase().initialise();
      await new RAMSDatabase().initialise();
      await new ServiceDatabase().initialise();
      await new UserDatabase().initialise();

      resolve();
    });
  }

  public getAll = (): Promise<void> => {
    return new Promise((resolve) => {
      var promises = [];
      promises.push(this.syncCompanies());
      promises.push(this.syncLocations());
      promises.push(this.syncMasterCategories());
      promises.push(this.syncCategories());
      promises.push(this.syncManufacturers());
      promises.push(this.syncDefects());
      promises.push(this.syncEquipment());
      promises.push(this.syncJobs());
      promises.push(this.syncPurposes());
      promises.push(this.syncColours());
      promises.push(this.syncRAMSQuestions());
      promises.push(this.syncInspectionQuestions());

      Promise.all(promises).then(() => {
        console.log("----------");
        console.log("FINISHED UPDATING");
        console.log("----------");
        resolve();
      })
    });
  }
}