import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert, Modal } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { RAMSDatabase } from '@store/services/database/rams-database';
import Checkbox from "expo-checkbox";
import { CategoryDatabase } from '@store/services/database/category-database';
import { QrModal } from '@app/components/QrModal';
import { EquipmentDatabase } from '@store/services/database/equipment-database';

export default function JobAssetsScreen({ navigation, route }: { navigation: any, route: any }) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const job = useSelector((state: RootState) => state.job);

  const [isLoading, setIsLoading] = useState(false);
  const [jobAssets, setJobAssets] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [equipmentForModal, setEquipmentForModal] = useState(null);
  const [qr, setQr] = useState(null);

  useEffect(() => {
    if (route.params != null) {
      if (route.params?.viewModel != null) {
        selectAsset(route.params.viewModel);
      }
    }
  }, [route.params]);

  const jobDatabase = new JobDatabase();
  const loadJobAssets = async (searchQuery = null) => {
    var jobAssets = await jobDatabase.getJobAssets(job.jobId, searchQuery);
    setJobAssets(jobAssets);
  }

  const confirmSelectAsset = async (viewModel) => {
    if (jobAssets.filter(j => j.isSelected).length > 0) {
      Alert.alert('Select Asset', 'Are you sure you want to inspect this asset? Any selected assets will be lost', [
        {
          text: 'No', style: 'cancel', onPress: async () => {

          }
        },
        {
          text: 'Yes', onPress: async () => {
            await selectAsset(viewModel);
          }
        }
      ]);
      return;
    }

    await selectAsset(viewModel);
  }

  const selectAsset = async (viewModel) => {
    // Check to see if we have a RAMS complete for this category
    var ramsDatabase = new RAMSDatabase();
    var ramsId = await ramsDatabase.getRamsId(job.jobId, viewModel.categoryId);

    if (ramsId == null) {
      Alert.alert("POWRA", "No POWRA has been fulfilled for this category. Please complete one now.");
      rams(viewModel.categoryId, viewModel);
      return;
    }

    dispatch(clearInspectionData());
    dispatch(setJobData({ equipmentId: viewModel.id, categoryId: viewModel.categoryId }))
    dispatch(setJobData({ ramsId: ramsId }))

    navigation.navigate("EquipmentDetails");
  }

  const rams = (categoryId, viewModel) => {
    navigation.navigate("RAMS", { categoryId: categoryId, viewModel: viewModel });
  }

  const erstr = () => {
    navigation.navigate("ERSTR");
  }

  const addEquipment = () => {
    navigation.navigate("EquipmentManage", { equipmentId: null })
  }

  const complete = async (promptEngineerReport: boolean = true) => {
    // Prompt for erstr
    // if (job.engineerReportId == null && promptEngineerReport) {
    //   Alert.alert('Complete Job', 'Do you want to carry out your engineer report now?', [        
    //     { text: 'No', style: 'cancel', onPress: () => complete(false)  },
    //     { text: 'Yes', onPress: () => erstr() }
    //   ]);
    //   return;
    // }

    if (jobAssets.length == 0) {
      await jobDatabase.completeJob(job.jobId);
      Alert.alert("Success", "Successfully completed Job");
      navigation.navigate('Dashboard');
      return;
    }

    Alert.alert('Complete Job', 'Completing this job will set the remaining assets as missing. Are you sure you want to do this?', [
      {
        text: 'No', style: 'cancel', onPress: async () => {

        }
      },
      {
        text: 'Yes', onPress: async () => {
          var inspectionDatabase = new InspectionDatabase();
          for (let jobAsset of jobAssets) {
            await inspectionDatabase.addMissingInspection(moment().format("YYYY-MM-DD"), jobAsset.id, job.engineerId, job.jobId, job.purposeId, job.colourId);
          }

          await jobDatabase.completeJob(job.jobId);
          Alert.alert("Success", "Successfully completed Job");
          navigation.navigate('Dashboard');
        }
      }
    ]);
    return;
  }

  const updateJobAssetSelected = (jobAsset, isSelected) => {
    jobAsset.isSelected = isSelected;
    setJobAssets([...jobAssets]);
  }

  const quickInspect = async () => {
    setIsLoading(true);
    var ramsDatabase = new RAMSDatabase();
    var assets = jobAssets.filter(j => j.isSelected);
    for (let asset of assets) {
      var ramsId = await ramsDatabase.getRamsId(job.jobId, asset.categoryId);
      if (ramsId == null) {
        setIsLoading(false);
        Alert.alert("POWRA", "No POWRA has been fulfilled for this " + asset.categoryName + ". Please complete one now.");
        return;
      }
    }

    var categoryDatabase = new CategoryDatabase();


    var inspectionDatabase = new InspectionDatabase();

    for (let asset of assets) {
      var ramsId = await ramsDatabase.getRamsId(job.jobId, asset.categoryId);
      const category = await categoryDatabase.getById(asset.categoryId);

      var inspectionDate = moment().format("YYYY-MM-DD");
      var nextInspectionDate = null;
      if (job.purposeInterval != 0) {
        nextInspectionDate = moment().add(job.purposeInterval, 'M').format("YYYY-MM-DD");
      }
      else {
        nextInspectionDate = moment().add(category.inspectionInterval, 'M').format("YYYY-MM-DD");
      }
      await inspectionDatabase.addInspection(inspectionDate, nextInspectionDate, false, true, [], null, null, true,
        asset.id, job.engineerId, job.jobId, ramsId, job.purposeId, job.colourId, false, [], []);
    }

    Alert.alert("Quick-Inspect", "Successfully quick-passed " + assets.length + " assets");

    loadJobAssets();
    setIsLoading(false);
  }

  useEffect(() => {
    loadJobAssets();
  }, [isFocused])

  const changeQr = (equipment) => {
    setModalVisible(true);
    setEquipmentForModal(equipment);
    setQr(equipment.qr || "");
  };

  const saveQr = async () => {
    if (equipmentForModal && qr) {
      const equipmentDatabase = new EquipmentDatabase();
      await equipmentDatabase.updateEquipmentQr(equipmentForModal.id, qr, true);
      Alert.alert("Success", "QR code updated successfully");
      loadJobAssets();
      setModalVisible(false);
    }
  };


  const renderItem = ({ item, index }) => (
    <View style={styles.row}>
      <View style={[styles.column, styles.body__column, styles.column_select]}>
        {item.allowQuickInspect == 1 &&
          <Checkbox style={{ padding: 10 }} value={item.isSelected == true} onValueChange={(val) => updateJobAssetSelected(item, val)} color={colours.primary} />
        }
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.description}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.categoryName}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.serialNumber}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.manufacturer}</Text>
      </View>
      <View style={{ width: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 5 }}>
        <TouchableOpacity style={styles.inspect__button} onPress={() => changeQr(item)}>
          <Text style={styles.inspect__button__text}>QR</Text>
          {item.qr ?
            <AntDesign name="edit" size={24} color="white" style={{ marginLeft: 5 }} /> :
            <AntDesign name="pluscircleo" size={24} color="white" style={{ marginLeft: 5 }} />
          }
        </TouchableOpacity>
      </View>
      <View style={{ width: 120, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
        <TouchableOpacity style={styles.inspect__button} onPress={() => confirmSelectAsset(item)}>
          <Text style={styles.inspect__button__text}>INSPECT</Text>
          <AntDesign name="rightcircleo" size={24} color="white" style={{ marginLeft: 10 }} />
        </TouchableOpacity>
      </View>
    </View>
  );

  if (isLoading) {
    return <ActivityIndicator />
  }

  return (
    <View style={styles.container}>
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <QrModal
              equipment={equipmentForModal}
              qr={qr}
              setQr={setQr}
              onCancel={() => {
                setModalVisible(false);
                setEquipmentForModal(null);
                setQr(null);
              }}
              onSave={saveQr}
            />
          </View>
        </View>
      </Modal>
      <TitleBar
        navigation={navigation}
        title="Job Assets"
        showBackButton={true}
        showSearchButton={true}
        onSynced={null}
        onSearch={(searchQuery) => loadJobAssets(searchQuery)} />

      {job != null &&
        <View style={styles.job__title}>
          <Text style={styles.job__title__text}>{job.jobTitle}</Text>

          <View style={styles.spacer} />

          {jobAssets.filter(j => j.isSelected).length > 0 &&
            <TouchableOpacity style={[styles.add__equipment__button]} onPress={() => quickInspect()}>
              <Entypo style={{ marginRight: 10 }} size={24} color={"#FFF"} name="check" />
              <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>INSPECT AS PASS ({jobAssets.filter(j => j.isSelected).length})</Text>
            </TouchableOpacity>
          }

          <TouchableOpacity onPress={() => addEquipment()} style={[styles.add__equipment__button]}>
            <Entypo style={{ marginRight: 10 }} size={24} color={"#FFF"} name="cog" />
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>ADD EQUIPMENT</Text>
          </TouchableOpacity>

          {/* <TouchableOpacity onPress={() => erstr()} style={[styles.rams__button, job.engineerReportId == null ? styles.rams__button__red : styles.rams__button__green]}>
            { job.engineerReportId == null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="cancel" size={24} color={"#FFF"} />}
            { job.engineerReportId != null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="check" size={24} color={"#FFF"} />}
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>ERSTR</Text>
          </TouchableOpacity> */}

          {/* <TouchableOpacity onPress={() => rams()} style={[styles.rams__button, job.ramsId == null ? styles.rams__button__red : styles.rams__button__green]}>
            { job.ramsId == null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="cancel" size={24} color={"#FFF"} />}
            { job.ramsId != null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="check" size={24} color={"#FFF"} />}
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>RAMS</Text>
          </TouchableOpacity> */}
        </View>
      }

      <View style={{ marginTop: 20, }}>
        <View style={styles.row}>
          <Text style={[styles.column, styles.head__column, styles.column_select]}></Text>
          <Text style={[styles.column, styles.head__column]}>Description</Text>
          <Text style={[styles.column, styles.head__column]}>Category</Text>
          <Text style={[styles.column, styles.head__column]}>Serial #</Text>
          <Text style={[styles.column, styles.head__column]}>Manufacturer</Text>
          <View style={{ width: 120 }}></View>
        </View>
      </View>

      <FlashList
        data={jobAssets}
        renderItem={renderItem}
        estimatedItemSize={57}
      />

      <TouchableOpacity style={styles.complete__button} onPress={() => complete()}>
        <Text style={styles.complete__button__text}>COMPLETE JOB</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  row: {
    marginBottom: 5,
    flexDirection: 'row',
  },

  column: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },

  column_select: {
    width: 100,
    flex: 0,
  },

  head__column: {
    padding: 15,
    paddingTop: 0,
    fontFamily: 'OpenSans-Bold',
  },

  body__column: {
    marginBottom: 5,
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },

  job__title: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#FFF',
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },

  job__title__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
  },

  spacer: {
    flex: 1,
  },

  job__text: {

  },

  add__equipment__button: {
    backgroundColor: colours.primary,
    padding: 10,
    borderRadius: 10,
    width: 190,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button: {
    backgroundColor: '#CECECE',
    padding: 10,
    borderRadius: 10,
    width: 100,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button__red: {
    backgroundColor: 'red'
  },

  rams__button__green: {
    backgroundColor: 'green'
  },

  complete__button: {
    backgroundColor: colours.success,
    padding: 10,
    borderRadius: 10,
    marginTop: 10,
    textAlign: 'center',
  },

  complete__button__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
    color: '#FFF',
    textAlign: 'center',
  },

  inspect__button: {
    backgroundColor: colours.primary,
    width: '100%',
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,

    justifyContent: 'center',
    alignItems: 'center'
  },

  inspect__button__text: {
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 600,
    borderRadius: 5,

    flexDirection: 'column',
  },
});


