import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';

export default function EquipmentDetailsScreen({ navigation, route }: { navigation: any, route: any }) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const job = useSelector((state: RootState) => state.job);

  const [equipment, setEquipment] = useState(null);


  const loadEquipmentDetails = async () => {
    const equipmentDatabase = new EquipmentDatabase();
    const equip = await equipmentDatabase.getById(job.equipmentId);
    setEquipment(equip);
    // var jobAssets = await jobDatabase.getJobAssets(job.jobId);
    // setJobAssets(jobAssets);
  }

  const viewFiles = () => {
    navigation.navigate("EquipmentViewFiles");
  }

  const edit = () => {
    navigation.navigate("EquipmentManage", { equipmentId: job.equipmentId })
  }

  const inspectAsset = async () => {

    switch (parseInt(job.jobType)) {
      case 0:
        navigation.navigate("RoteInspect");
        return;
      case 1:
        navigation.navigate("ServiceInspect");
        return;
      case 2:
        navigation.navigate("BreakdownInspect");
        return;
      case 3:
        navigation.navigate("ProofLoadInspect");
        return;
    }
  }


  useEffect(() => {
    loadEquipmentDetails();
  }, [isFocused])

  if (equipment == null) {
    return <LoadingIndicator />
  }


  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Equipment Details" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null} />

      <View style={styles.form}>
        <View style={styles.form__row}>
          <View style={styles.column}>
            <Text style={styles.column__label}>Description</Text>
            <Text style={styles.column__value}>{equipment.description}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.column__label}>Serial #</Text>
            <Text style={styles.column__value}>{equipment.serialNumber}</Text>
          </View>
        </View>
      </View>

      <View style={styles.form__row}>
        <View style={styles.column}>
          <Text style={styles.column__label}>Asset #</Text>
          <Text style={styles.column__value}>{equipment.assetNumber}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.column__label}>SWL</Text>
          <Text style={styles.column__value}>{equipment.swl}</Text>
        </View>
      </View>

      <View style={styles.form__row}>
        <View style={styles.column}>
          <Text style={styles.column__label}>Manufacturer</Text>
          <Text style={styles.column__value}>{equipment.manufacturerName}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.column__label}>Category</Text>
          <Text style={styles.column__value}>{equipment.categoryName}</Text>
        </View>
      </View>

      {/* <View style={styles.form__row}>
        <View style={styles.column}>
          <Text style={styles.column__label}>Other Details</Text>
          <Text style={styles.column__value}>{equipment.otherDetails}</Text>
        </View>
      </View> */}

      <View style={[styles.form__row, styles.form__row__margin]}>
        <TouchableOpacity style={[styles.column, styles.button]} onPress={() => viewFiles()}>
          <Text style={styles.button__text}>VIEW FILES</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.column, styles.button]} onPress={() => edit()}>
          <Text style={styles.button__text}>EDIT</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.column, styles.button]} onPress={() => inspectAsset()}>
          <Text style={styles.button__text}>INSPECT</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    marginTop: 10,
  },

  form__row: {
    flexDirection: 'row',
  },

  form__row__margin: {
    marginTop: 20,
  },

  column: {
    flex: 1,
    margin: 5,
    flexDirection: 'column'
  },

  column__label: {
    fontWeight: 'bold',
    fontSize: 18
  },

  column__value: {
    padding: 10,
    backgroundColor: '#ebe8e8',
    borderRadius: 5,
    marginTop: 5,
    fontSize: 24,
    borderWidth: 1,
    borderColor: '#cecece'
  },

  button: {
    backgroundColor: colours.primary,
    padding: 15,
  },

  button__text: {
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
    fontSize: 32
  }


});
